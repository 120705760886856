
export const ALL_PROPERTY_REQUEST = 'ALL_PROPERTY_REQUEST'
export const ALL_PROPERTY_SUCCESS = 'ALL_PROPERTY_SUCCESS'
export const ALL_PROPERTY_FAIL = 'ALL_PROPERTY_FAIL'

export const ALL_UNIT_REQUEST = 'ALL_UNIT_REQUEST'
export const ALL_UNIT_SUCCESS = 'ALL_UNIT_SUCCESS'
export const ALL_UNIT_FAIL = 'ALL_UNIT_FAIL'



export const CREATE_PROPERTY = "CREATE_PROPERTY_MAINTENANCE"
export const CREATE_PROPERTY_SUCCESS = "CREATE_PROPERTY_SUCCESS"
export const CREATE_PROPERTY_FAIL = "CREATE_PROPERTY_FAIL"


export const CREATE_UNIT = "CREATE_UNIT_MAINTENANCE"
export const CREATE_UNIT_SUCCESS = "CREATE_UNIT_SUCCESS"
export const CREATE_UNIT_FAIL = "CREATE_UNIT_FAIL"



export const CLEAR_ERRORS = 'CLEAR_ERRORS'

